






















import { Vue, Component } from "vue-property-decorator";
import MessageGroupTable from "@/components/message-group/MessageGroupTable.vue";
import MessageGroupCreateDialog from "@/components/dialogs/MessageGroupCreateDialog.vue";
import MessageGroupUpdateDialog from "@/components/dialogs/MessageGroupUpdateDialog.vue";
//import { MessageGroupModel } from "@/api/generated";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import { appApiClient } from "@/services/apiService";
import { NewMessageModel, ChatRoom } from '../../models/MessageGroupModel';
import userModule from "@/store/userModule";
import { TokenModel } from '../../api/generated/api';

const snackbarModule = getModule(SnackbarModule);

@Component({
  components: {
    MessageGroupTable,
    MessageGroupCreateDialog,
    MessageGroupUpdateDialog
  }
})
export default class MessageGroupOverview extends Vue {
  private tableRefreshKey = false;
  public createMessageGroup = false;
  public updateMessageGroup = false;
  public groupId?: number;

  /**
   *
   */
  constructor() {
    super();
    this.groupId = 0;
  }

  private onCreateMessageGroup() {
    this.createMessageGroup = true;
  }

  private onUpdateMessageGroup(messageGroup: ChatRoom) {
    if (!messageGroup.id) {
      return;
    }

    this.groupId = messageGroup.id;
    this.updateMessageGroup = true;
  }

  private onMessageGroupCreated() {
    this.tableRefreshKey = !this.tableRefreshKey;
  }

  private onMessageGroupUpdated() {
    this.tableRefreshKey = !this.tableRefreshKey;
  }

  private async onMessageGroupDeleted(messageGroup: ChatRoom) {
    if (!messageGroup.id) {
      return;
    }

    try {
      // No structured clone in this version.
      messageGroup = JSON.parse(JSON.stringify(messageGroup));

      // TODO: BAD CODE: Use axios interceptors instead.
      const module = getModule(userModule);
      const user = localStorage.getItem("user");
      if (user) {
        const userModel = JSON.parse(user) as TokenModel;
        await module.refreshToken(userModel);
      }

      messageGroup.isActive = false;

      const users: string[] = messageGroup.participants.map((user: any) => {
        return user.id;
      });

      messageGroup.participants = users;

      await appApiClient.put(`api/admin/UpdateGroup/${messageGroup.id}`, messageGroup);
      /*
      await api.MessageGroupService.apiVesseltypeVesselTypeIdDelete(
        messageGroup.messageGroupId
      );
      */
      
      snackbarModule.setSnackbarMessage("Message group deleted");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to delete message group");
    } finally {
      this.tableRefreshKey = !this.tableRefreshKey;
    }
  }
}
