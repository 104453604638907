var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"message-rooms-table"},[_c('base-data-table',{attrs:{"title":"Message Groups","headers":_vm.headers,"items":_vm.messageRooms,"loading":_vm.loading},on:{"create-item":function($event){return _vm.$emit('create-message-group')}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.createdAt))+" ")]}},{key:"item.participants",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatParticipants(item.participants))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","min-width":"120"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.$emit('update-message-group', item)}}},[_c('v-list-item-title',[_vm._v("Edit")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.$emit('delete-message-group', item)}}},[_c('v-list-item-title',[_vm._v("Delete")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }