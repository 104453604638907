










































import { Component, PropSync, Ref, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { VForm } from "@/types/vForm";
import SnackbarModule from "@/store/snackbarModule";
import Validation from "@/mixins/validation";
import Api from "@/api";
import { JobRoleUpsertModel } from "@/api/generated";
import { appApiClient } from "@/services/apiService";
import UserAutocomplete from "@/components/common/user/UserAutocomplete.vue";
import reduce from "image-blob-reduce";
import { NewMessageModel } from "../../models/MessageGroupModel";
import userModule from "@/store/userModule";
import { TokenModel } from "../../api/generated/api";

const snackbarModule = getModule(SnackbarModule);

@Component({
  components: {
    UserAutocomplete,
  },
})
export default class MessageGroupCreateDialog extends Validation {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Ref() private readonly form!: VForm;

  private loading = false;
  public thumbnail: File | null = null;
  private model: NewMessageModel = {
    name: "",
    participants: [],
    thumbnail: undefined,
    thumbnailName: undefined,
  };

  @Watch("syncedDialog")
  private onDialogClose() {
    if (!this.syncedDialog) {
      return;
    }

    this.form?.reset();
  }

  public async onMessageGroupCreate() {
    if (!this.form.validate()) {
      return;
    }

    this.loading = true;

    interface MediaResponse {
      blob: MediaBlob;
      error: string;
    }

    interface MediaBlob {
      uniqueKey: string;
    }

    try {
      const response: MediaResponse = await this.uploadFile(this.thumbnail);

      const attachedFileKey: string | null = response?.blob?.uniqueKey;

      this.model.thumbnail = attachedFileKey;

      if (this.thumbnail) {
        this.model.thumbnailName = this.thumbnail.name;
      }

      await appApiClient.post("api/admin/CreateGroup", this.model);
      snackbarModule.setSnackbarMessage("Message group created");
      this.$emit("message-group-created");
    } catch (error) {
      snackbarModule.setSnackbarMessage("Failed to create message group");
    } finally {
      this.loading = false;
      this.syncedDialog = false;
    }
  }

  private async uploadFile(file: File | null) {
    if (!file) return;

    file = await this.downSizeImage(file);

    // Check file size before upload. // 4 mb.
    if (file.size > 4 * 1024 * 1024) {
      snackbarModule.setSnackbarMessage("File too big (4mb max).");
    }

    const formData = new FormData();
    formData.append("file", file, file.name);

    try {
      const response = await appApiClient.post("api/media/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      snackbarModule.setSnackbarMessage("Could not upload image.");
    }
  }

  private async downSizeImage(file: File): Promise<File> {
    // Images are 300 x 300.
    const blob = await reduce().toBlob(file, { max: 300 });

    const newFile = new File([blob], file.name, {
      type: file.type,
    });

    return newFile;
  }
}
