





















































import { Component, PropSync, Ref, Watch, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { VForm } from "@/types/vForm";
import SnackbarModule from "@/store/snackbarModule";
import Validation from "@/mixins/validation";
import Api from "@/api";
import { JobRoleUpsertModel } from "@/api/generated";
import { appApiClient } from "@/services/apiService";
import { NewMessageModel, ChatRoom } from "@/models/MessageGroupModel";
import UserAutocomplete from "@/components/common/user/UserAutocomplete.vue";
import reduce from "image-blob-reduce";
import User from "@/models/User";
import userModule from "@/store/userModule";
import { TokenModel } from "../../api/generated/api";

const snackbarModule = getModule(SnackbarModule);

@Component({
  components: {
    UserAutocomplete,
  },
})
export default class MessageGroupCreateDialog extends Validation {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Prop(Number) public groupId!: number;
  @Ref() private readonly form!: VForm;

  public loading = false;
  public thumbnail: File | null = null;
  public model: NewMessageModel = {
    name: "",
    participants: [],
    thumbnail: undefined,
    thumbnailName: undefined,
  };

  get thumbnailName(): string {
    return this.thumbnail ? this.thumbnail.name : "";
  }

  public async onNewFileAttached(file: File) {
    this.thumbnail = file;
  }

  public async onClear() {
    this.model.thumbnailName = undefined;
  }

  @Watch("syncedDialog")
  private async onDialogOpen() {
    if (!this.syncedDialog) {
      return;
    }

    try {
      const response = await appApiClient.get("/api/admin/GetGroups");

      if (!response.data) throw new Error();

      const room: ChatRoom = response.data.find(
        (room: ChatRoom) => room.id === this.groupId
      );
      const users: string[] = room.participants.map((user: any) => {
        return user.id;
      });

      const model: NewMessageModel = {
        name: room.name,
        thumbnail: room.thumbnail,
        thumbnailName: room.thumbnailName,
        participants: users,
      };

      this.model = model;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to fetch crew member note");
      this.syncedDialog = false;
    }
  }

  public async onMessageGroupCreate() {
    if (!this.form.validate()) {
      return;
    }

    this.loading = true;

    interface MediaResponse {
      blob: MediaBlob;
      error: string;
    }

    interface MediaBlob {
      uniqueKey: string;
    }

    try {
      if (this.thumbnail) {
        const response: MediaResponse = await this.uploadFile(this.thumbnail);

        const attachedFileKey: string | null = response?.blob?.uniqueKey;

        this.model.thumbnail = attachedFileKey;
        this.model.thumbnailName = this.thumbnail.name;
        this.thumbnail = null;
      }

      await appApiClient.put(
        `api/admin/UpdateGroup/${this.groupId}`,
        this.model
      );
      snackbarModule.setSnackbarMessage("Message group created");
      this.$emit("message-group-updated");
    } catch (error) {
      snackbarModule.setSnackbarMessage("Failed to create message group");
    } finally {
      this.loading = false;
      this.syncedDialog = false;
    }
  }

  private async uploadFile(file: File | null) {
    if (!file) return;

    file = await this.downSizeImage(file);

    // Check file size before upload. // 4 mb.
    if (file.size > 4 * 1024 * 1024) {
      snackbarModule.setSnackbarMessage("File too big (4mb max).");
    }

    const formData = new FormData();
    formData.append("file", file, file.name);

    try {
      const response = await appApiClient.post("api/media/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      snackbarModule.setSnackbarMessage("Could not upload image.");
    }
  }

  private async downSizeImage(file: File): Promise<File> {
    // Images are 300 x 300.
    const blob = await reduce().toBlob(file, { max: 300 });

    const newFile = new File([blob], file.name, {
      type: file.type,
    });

    return newFile;
  }
}
