






































import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { getModule } from "vuex-module-decorators";
import nameof from "@/utility/nameof";
import SnackbarModule from "@/store/snackbarModule";
import { JobRoleModel, TokenModel } from "@/api/generated";
import Api from "@/api";
import { apiClient, appApiClient } from "@/services/apiService";
import environment from "@/environment";
import axios, { AxiosRequestConfig } from "axios";
import User from "@/models/User";
import { ChatRoom } from '../../models/MessageGroupModel';
import userModule from "@/store/userModule";

const snackbarModule = getModule(SnackbarModule);

@Component
export default class MessageGroupTable extends Vue {
  private loading = false;
  private messageRooms: Array<ChatRoom> = [];
  private headers: Array<DataTableHeader<ChatRoom>> = [
    { text: "", value: "actions", sortable: false },
    { text: "Name", value: nameof<ChatRoom>("name") },
    { text: "Participants", value: nameof<ChatRoom>("participants") },
    { text: "Created", value: nameof<ChatRoom>("createdAt") }
  ];

  private async created() {
    await this.getMessageGroups();
  }

  private async getMessageGroups() {
    try {
      this.loading = true;
      this.messageRooms = [];
      await this.$nextTick();
      // Bit annoying that we can't use generated api client as we don't have a openapi spec on the same server as rest of endpoints
      const response = await appApiClient.get("/api/admin/GetGroups");

      this.messageRooms = response.data;
    }
    finally {
      this.loading = false;
    }
  }

  private formatParticipants(participants: Array<User>): string {
    const csvList = participants
      .map((member: User) => `${member?.firstname} ${member?.lastname}`)
      .join(", ");

    return csvList;
  }
}
